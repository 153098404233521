define('due-dashboard/components/v2/libraries/surveys/navbar/translations/elements/-choice', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['survey-translations-element'],

    choiceValues: computed(function () {
      var choiceValues = [];
      (this.get('surveyElement').content.options || []).forEach(function (option) {
        choiceValues.push({ name: option.key, value: option.value });
      });
      return choiceValues;
    }),

    languages: computed('languagesToDisplay', function () {
      var _this = this;

      var languages = [];
      var mainLanguageTag = this.get('mainLanguage.tag');
      var choiceValues = this.get('choiceValues');
      this.get('languagesToDisplay').forEach(function (lang) {
        if (lang == mainLanguageTag) {
          var surveyElement = _this.get('surveyElement');
          languages.push({
            name: lang,
            mainLanguage: true,
            label: surveyElement.content.label,
            placeholder: surveyElement.content.placeholder,
            values: choiceValues
          });
        } else {
          var element = _this._getElement(lang.toLowerCase());
          languages.push({
            name: lang,
            mainLanguage: false,
            label: _this._readNestedObject(element, 'label'),
            placeholder: _this._readNestedObject(element, 'placeholder'),
            values: _this._getTranslatedOptionValues(choiceValues, _this._readNestedObject(element, 'options') || [])
          });
        }
      });
      return languages;
    }),

    _getTranslatedOptionValues: function _getTranslatedOptionValues(optionValues, options) {
      if (optionValues == undefined) {
        return [];
      }
      return optionValues.map(function (option) {
        return { name: option.name, value: options[option.name] };
      });
    },

    _getElement: function _getElement(lang) {
      var translations = this.get('translations');
      var stepPosition = String(this.get('stepPosition'));
      var elementPosition = String(this.get('surveyElement.position'));
      if (translations == undefined) {
        return {};
      }
      return (((translations[lang] || {})['elements'] || {})[stepPosition] || {})[elementPosition];
    },

    _ensureExistence: function _ensureExistence(lang, stepPosition, elementPosition) {
      var translations = this.get('translations') || {};
      if (translations[lang] == undefined) {
        translations[lang] = {};
      }
      if (translations[lang]['elements'] == undefined) {
        translations[lang]['elements'] = {};
      }
      if (translations[lang]['elements'][stepPosition] == undefined) {
        translations[lang]['elements'][stepPosition] = {};
      }
      if (translations[lang]['elements'][stepPosition][elementPosition] == undefined) {
        translations[lang]['elements'][stepPosition][elementPosition] = {};
      }
      return translations;
    },

    _updateTranslations: function _updateTranslations(translationsElement, elementPath, value) {
      var _elementPath$split = elementPath.split('.');

      var _elementPath$split2 = _toArray(_elementPath$split);

      var head = _elementPath$split2[0];

      var rest = _elementPath$split2.slice(1);

      return _extends({}, translationsElement, _defineProperty({}, head, rest.length ? this._updateTranslations(translationsElement[head], rest.join('.'), value) : value));
    },

    _readNestedObject: function _readNestedObject(translationsElement, elementPath) {
      var _elementPath$split3 = elementPath.split('.');

      var _elementPath$split32 = _toArray(_elementPath$split3);

      var head = _elementPath$split32[0];

      var rest = _elementPath$split32.slice(1);

      if (translationsElement == undefined || translationsElement[head] == undefined) {
        return undefined;
      }
      return rest.length ? this._readNestedObject(translationsElement[head], rest.join('.')) : translationsElement[head];
    },

    actions: {
      setTranslations: function setTranslations(lg, elementPath, value) {
        var lang = lg.toLowerCase();
        var stepPosition = String(this.get('stepPosition'));
        var elementPosition = String(this.get('surveyElement.position'));
        var translations = this._ensureExistence(lang, stepPosition, elementPosition);
        var translationsElement = translations[lang]['elements'][stepPosition][elementPosition];
        translations[lang]['elements'][stepPosition][elementPosition] = this._updateTranslations(translationsElement, elementPath, value);
        this.set('translations', translations);
        this.sendAction('updateSurveyTranslations', translations);
      },
      setTranslatedOption: function setTranslatedOption(lg, score, value) {
        var lang = lg.toLowerCase();
        var stepPosition = String(this.get('stepPosition'));
        var elementPosition = String(this.get('surveyElement.position'));
        var translations = this._ensureExistence(lang, stepPosition, elementPosition);
        var options = translations[lang]['elements'][stepPosition][elementPosition]['options'] || [];
        translations[lang]['elements'][stepPosition][elementPosition]['options'] = _extends({}, options, _defineProperty({}, score, value));
        this.set('translations', translations);
        this.sendAction('updateSurveyTranslations', translations);
      }
    }
  });
});