define('due-dashboard/objects/reports/data-converter/segment-table-converter', ['exports', 'due-dashboard/objects/reports/data-converter/table-converter'], function (exports, _dueDashboardObjectsReportsDataConverterTableConverter) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _dueDashboardObjectsReportsDataConverterTableConverter['default'].extend({

    getHeaders: function getHeaders(tableParams, selectedViewBy, currentLanguage) {
      var headerTxt = tableParams.translations[currentLanguage].headers;
      var headerTooltip = tableParams.translations[currentLanguage].header_tooltip;
      if (tableParams.view_by) {
        headerTxt.splice(0, 1, selectedViewBy);
      }
      headerTxt = headerTxt.map(function (txt) {
        return txt.length > 70 ? txt.slice(0, 70) + '...' : txt;
      });

      var headers = [];
      var mainColumnNb = headerTxt.length - tableParams.properties.length;
      if (mainColumnNb < 1 || mainColumnNb == undefined) {
        return [];
      }
      headerTxt.forEach(function (txt, index) {
        var header = undefined;
        if (index < mainColumnNb) {
          header = {
            type: 'label-with-sub',
            label: txt,
            main_column: true
          };
        } else {
          header = {
            type: tableParams.properties[index - mainColumnNb].type,
            label: txt,
            main_column: false
          };
        }
        if (headerTooltip != null && headerTooltip[txt]) {
          header.tooltip = headerTooltip[txt];
        }
        headers.push(header);
      });
      return headers;
    },

    getRows: function getRows(data, tableParams, currentLanguage) {
      var rows = [];
      var content = this._getSegmentsContent(data, tableParams, currentLanguage);
      content.forEach(function (line, index) {
        return rows.push({ data: line });
      });
      return rows;
    },

    _formatBreakdown: function _formatBreakdown(elem, properties, tableParams, currentLanguage) {
      var value = _extends({}, elem.value);

      if (!value.breakdown) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      var distribution = value.breakdown;
      var total = distribution['_total'] || distribution['_total_multi'] || 0;
      distribution = this._removeMetadata(distribution);

      if (total == 0) {
        Object.values(distribution).forEach(function (sectionTotal) {
          total += sectionTotal;
        });
      }

      if (total == 0) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      var computedBreakdown = {};
      computedBreakdown = Object.keys(distribution).map(function (sectionKey, idx) {
        var sectionTotal = distribution[sectionKey];
        var sectionPercentage = sectionTotal / total * 100;
        var color = '';
        var title = '';

        if (properties.kpi && properties.kpi.includes('breakdown')) {
          title = sectionKey;
        } else {
          title = tableParams.translations[currentLanguage].distribution[idx].title;
        }

        var color_e = null;
        if (properties.segment_id && tableParams['distribution_' + properties.segment_id]) {
          color_e = tableParams['distribution_' + properties.segment_id][idx % tableParams['distribution_' + properties.segment_id].length];
        } else {
          color_e = properties.distribution[idx % properties.distribution.length];
        }
        color = color_e ? color_e.color : '#e6e6e6'; // default color for missing distribution entries

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'width: ' + sectionPercentage + '%; background-color: ' + color + ';',
          title: title,
          total: sectionTotal || 0
        };
      });
      value.distribution = computedBreakdown;
      value.total = total;
      return _extends({}, elem, { value: value });
    },

    _getSegmentsContent: function _getSegmentsContent(content, tableParams, currentLanguage) {
      var _this = this;

      var serializedSegments = [];
      content.forEach(function (line, lineIdx) {
        serializedSegments.push([]);
        if (tableParams.index) {
          serializedSegments[lineIdx].push({ type: 'index', main_column: true, value: lineIdx + (tableParams.page_number - 1) * tableParams.page_size });
        }
        line.forEach(function (elem, idx) {
          if (idx === 0) {
            serializedSegments[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else {
            if (elem.type == 'breakdown') {
              var breakdown = _this._formatBreakdown(elem, tableParams.properties[idx - 1], tableParams, currentLanguage);
              breakdown.value.title = tableParams.translations[currentLanguage].headers[idx];
              serializedSegments[lineIdx].push(breakdown);
            } else if (elem.type == 'number-trend') {
              serializedSegments[lineIdx].push(_this._formatNumberTrend(elem, tableParams.properties[idx - 1], tableParams));
            } else if (['positive-impact', 'negative-impact'].includes(elem.type)) {
              serializedSegments[lineIdx].push(_extends({}, elem, { positiveImpactHideValue: tableParams.positiveImpactHideValue, negativeImpactHideValue: tableParams.negativeImpactHideValue }));
            } else {
              serializedSegments[lineIdx].push(_extends({}, elem, { kpi: tableParams.properties[idx - 1].kpi, attrs: tableParams.properties[idx - 1].attrs }));
            }
          }
        });
      });
      return serializedSegments;
    },

    formatData: function formatData(params) {
      var tableParams = params.tableParams;
      var data = params.data;
      var selectedViewBy = params.selectedViewBy;
      var currentLanguage = params.currentLanguage;

      return {
        headers: this.getHeaders(tableParams, selectedViewBy, currentLanguage || 'EN'),
        rows: this.getRows(data, tableParams, currentLanguage || 'EN')
      };
    }
  });
});