define('due-dashboard/models/tag-breakdown-export-request', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    content: _emberData['default'].attr('string'),
    content_type: _emberData['default'].attr('string'),
    export_type: _emberData['default'].attr('string'),
    file_file_name: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    created_at: _emberData['default'].attr('string', { readOnly: true }),
    url: _emberData['default'].attr('string', { readOnly: true }),
    send_email: _emberData['default'].attr('boolean', { defaultValue: true }),
    prettyCreatedAt: computed('created_at', function () {
      return this.get('created_at') ? (0, _moment['default'])(this.get('created_at')).format('LLL') : '';
    })
  });
});