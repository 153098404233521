define('due-dashboard/components/selected-feedback-chat', ['exports', 'ember', 'moment', 'due-dashboard/config/environment', 'due-dashboard/utils/cookie_encode', 'due-dashboard/helpers/tag-display-name'], function (exports, _ember, _moment, _dueDashboardConfigEnvironment, _dueDashboardUtilsCookie_encode, _dueDashboardHelpersTagDisplayName) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  var Component = _ember['default'].Component;
  var htmlSafe = _ember['default'].String.htmlSafe;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Component.extend({

    tagManager: _ember['default'].inject.service(),

    typesCanAnswers: { "google_my_business": 1, "trustpilot_review": 1 },
    currentCompany: computed.alias('currentAccount.content.company'),
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    note: false,
    newNoteText: '',
    triggerDropDownReset: false,
    flatLevelDisplay: false,
    tagSkeleton: ['small', 'large', 'small', 'medium'],
    showSegments: false,

    canUseAutoTags: computed.alias('currentAccount.canUseAutoTags'),
    canSeeAutoTags: computed.alias('currentAccount.canSeeAutoTags'),
    canSeeTags: computed.alias('currentAccount.canSeeTags'),
    isReadOnly: computed.alias('currentAccount.isReadOnly'),
    fromNewSurvey: computed.alias('selectedFeedback.is_from_new_survey'),

    hasComment: computed('selectedFeedback.comment', function () {
      var comment = this.get('selectedFeedback.comment');
      return comment && comment.length;
    }),

    _cookieKey: computed('currentAccount.content', function () {
      return _dueDashboardUtilsCookie_encode['default'].btoa(this.get('currentAccount.content.id') + "segments_chat_cookie");
    }),

    init: function init() {
      var cookieService = localStorage;
      var segmentCookies = cookieService.getItem(this.get('_cookieKey'));
      this.set('showSegments', segmentCookies !== 'false');
      this.set('note', this.get('currentCompany.note_by_default') || !this.get('canAnswerFeedback'));
      this._super.apply(this, arguments);
    },

    hideTagDropdown: computed('currentAccount', function () {
      var ca = this.get('currentAccount');
      return !ca.get('canUseTags') && !ca.get('canUseAutoTags') || this.get('isReadOnly');
    }),

    solvedDate: computed('selectedFeedback.last_resolution_date', 'selectedFeedback.first_resolution_date', function () {
      var fb = this.get('selectedFeedback');
      var date = fb.get('last_resolution_date') ? fb.get('last_resolution_date') : fb.get('first_resolution_date');
      return this._formatedDate(date);
    }),

    isWorldwide: computed('selectedFeedback.source_zone', 'currentAccount.isFromChina', function () {
      return this.get('currentAccount.isFromChina') || this.get('selectedFeedback.source_zone') !== 'china' && !this.get('currentAccount.isFromChina');
    }),

    displayResolverEmail: computed('selectedFeedback.resolver_account.email', function () {
      return this.get('selectedFeedback.resolver_account.content') !== null;
    }),

    hideResolutionTime: computed('selectedFeedback.resolved', 'selectedFeedback.new_resolution_time_cache', function () {
      var fb = this.get('selectedFeedback');
      return fb.get('resolved') && fb.get('new_resolution_time_cache') === null;
    }),

    displayResolverMeta: computed('selectedFeedback.resolved', 'selectedFeedback.new_resolution_time_cache', 'selectedFeedback.last_resolution_date', 'selectedFeedback.first_resolution_date', function () {
      var fb = this.get('selectedFeedback');
      return fb.get('resolved') && fb.get('new_resolution_time_cache') !== null && (fb.get('last_resolution_date') || fb.get('first_resolution_date'));
    }),

    resolutionThresholdExceeds: computed('selectedFeedback', 'currentCompany.resolution_time_threshold', function () {
      var fb = this.get('selectedFeedback');
      var companyThreshold = this.get('currentCompany.resolution_time_threshold');
      if (fb.get('resolved')) return Math.floor(fb.get('new_resolution_time_cache') / 3600) >= companyThreshold;
      var end = fb.get('last_opening_date') ? fb.get('last_opening_date') : fb.get('answer_completed_at');
      return Math.floor((new Date().getTime() - end.getTime()) / 3600000) >= companyThreshold; // Milli to hour
    }),

    computedOpeningTime: computed('selectedFeedback.last_opening_date', 'selectedFeedback.answer_completed_at', function () {
      var fb = this.get('selectedFeedback');
      var end = fb.get('last_opening_date') ? fb.get('last_opening_date') : fb.get('answer_completed_at');

      var _calcDurationDayHour2 = this._calcDurationDayHour(new Date(), end);

      var days = _calcDurationDayHour2.days;
      var hours = _calcDurationDayHour2.hours;
      var minutes = _calcDurationDayHour2.minutes;

      return this._formatedTime(days, hours, minutes);
    }),

    computedClosingTime: computed('selectedFeedback.new_resolution_time_cache', function () {
      var rdc = this.get('selectedFeedback.new_resolution_time_cache');
      if (!rdc) return;
      var days = Math.floor(rdc / (3600 * 24));
      var hours = Math.floor(rdc % (3600 * 24) / 3600);
      var minutes = Math.floor(rdc % 3600 / 60);
      return rdc < 60 ? "< 1 min" : this._formatedTime(days, hours, minutes);
    }),

    _formatedTime: function _formatedTime(days, hours, minutes) {
      if (days > 0) return days + 'd ' + hours + 'h';
      if (hours > 0) return hours + 'h ' + minutes + 'm';
      return minutes + ' min';
    },

    _formatedDate: function _formatedDate(date) {
      var sep = arguments.length <= 1 || arguments[1] === undefined ? '/' : arguments[1];

      var language = this.get('currentLanguage');
      var padTo2Digits = function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      };
      return [padTo2Digits(language === 'EN' ? date.getMonth() + 1 : date.getDate()), padTo2Digits(language === 'EN' ? date.getDate() : date.getMonth() + 1), date.getFullYear()].join(sep);
    },

    _calcDurationDayHour: function _calcDurationDayHour(start, end) {
      var delta = Math.abs(start.getTime() - end.getTime()) / 1000;
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      return { days: days, hours: hours, minutes: minutes };
    },

    showRootCauses: computed('currentCompany', 'selectedFeedback.resolved', 'selectedFeedbackRootCauses', 'isWorldwide', function () {
      return (this.get('isWorldwide') || this.get('selectedFeedbackRootCauses.length') > 0) && (this.get('currentCompany.enabled_root_cause') && !this.get('currentCompany.modal_resolution_enabled') || this.get('currentCompany.enabled_root_cause') && this.get('selectedFeedbackRootCauses.length') > 0 || this.get('selectedFeedback.resolved') && (this.get('selectedFeedbackRootCauses.length') > 0 || this.get('currentCompany.enabled_root_cause')));
    }),

    showActionsTaken: computed('currentCompany', 'selectedFeedback.resolved', 'selectedFeedbackActionsTaken', 'isWorldwide', function () {
      return (this.get('isWorldwide') || this.get('selectedFeedbackActionsTaken.length') > 0) && (this.get('currentCompany.action_taken_enabled') && !this.get('currentCompany.modal_resolution_enabled') || this.get('currentCompany.action_taken_enabled') && this.get('selectedFeedbackActionsTaken.length') > 0 || this.get('selectedFeedback.resolved') && (this.get('selectedFeedbackActionsTaken.length') > 0 || this.get('currentCompany.action_taken_enabled')));
    }),

    showTags: computed('selectedFeedback.source_zone', 'selectedFeedbackTags', 'isWorldwide', function () {
      var seeTagsRights = this.get('canSeeTags') || this.get('canSeeAutoTags');
      var useTagsRights = this.get('canUseTags') || this.get('canUseAutoTags');
      return seeTagsRights && this.get('selectedFeedbackTags.length') > 0 || useTagsRights && this.get('isWorldwide');
    }),

    actionsTakenList: computed('selectedFeedback.resolved', 'selectedFeedback.actions_taken', function () {
      var _this = this;

      var list = this.get('selectedFeedbackActionsTaken').map(function (fbt) {
        var tag = fbt.get('tag');
        if (_this.get('currentLanguage') && tag.get('translations')) {
          return tag.get('translations')[_this.get('currentLanguage').toLowerCase()] ? tag.get('translations')[_this.get('currentLanguage').toLowerCase()]['text'] : tag.get('name');
        }
        return tag.get('name');
      }).join(', ');
      return list + (list.length > 0 ? ', ' : '');
    }),

    realTags: computed('tags', 'selectedFeedback.tags', 'currentAccount.content.profile', function () {
      return this._computeReal(this.get('tags'));
    }),

    realRootCauses: computed('rootCauses', 'selectedFeedback.tags', 'currentAccount.content.profile', function () {
      return this._computeReal(this.get('rootCauses'));
    }),

    realActionsTaken: computed('actionsTaken', 'selectedFeedback.tags', 'currentAccount.content.profile', function () {
      return this._computeReal(this.get('actionsTaken'));
    }),

    _computeReal: function _computeReal(tags) {
      var _this2 = this;

      var res = [],
          flatLevelDisplay = this.get('flatLevelDisplay'),
          selectedTags = this.get('selectedFeedback.tags');
      if (tags !== undefined && flatLevelDisplay === false) {
        tags.forEach(function (tag, _, array) {
          _this2._computeTags(array, tag, res, selectedTags);
        });
      } else if (tags !== undefined) {
        tags.forEach(function (tag) {
          return _this2._buildTagsFlatLevelRows(tag, res, selectedTags);
        });
      }
      return res;
    },

    _buildTagsFlatLevelRows: function _buildTagsFlatLevelRows(tag, res, selectedTags) {
      tag.set('title', _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')));
      tag.set('disabled', selectedTags.find(function (selected) {
        return selected.get('name') == tag.get('name');
      }) !== undefined);
      res.push(tag);
    },

    _computeTags: function _computeTags(tags, tag, res, selectedTags) {
      var _this3 = this;

      var splitName = tag.get('name').split(' > ');
      var splitTitle = _dueDashboardHelpersTagDisplayName['default'].compute(tag, this.get('currentLanguage')).split('>');
      var depth = splitName.length - 1;
      tag.set('depth', splitName.length - 1);
      tag.set('displayName', splitName[tag.get('depth')]);
      tag.set('title', splitTitle[depth]);
      var subTagRegExp = "^" + tag.get('name').replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";
      var subTags = tags.filter(function (item) {
        return item.get('name').match(subTagRegExp);
      });
      subTags = subTags.map(function (subTag) {
        _this3._computeTags(tags, subTag, res, selectedTags);
        subTag.set('parentTag', tag);
        return subTag;
      });

      var disabled = selectedTags.find(function (selected) {
        return selected.get('name') == tag.get('name');
      }) !== undefined || tag.get('not_assignable');
      if (tag.get('due_exclusive') || this.get('canUseAutoTags')) {
        disabled = !this.get('canUseAutoTags') ? true : disabled; // cannot assign tag if read only right
      }
      if (subTags.length > 0) {
        if (!tag.get('not_assignable')) {
          var generalTag = {
            title: 'General', name: 'general', type: 'general',
            realTag: tag, must_be_first: true, disabled: disabled,
            feeling_enabled: tag.get('feeling_enabled')
          };
          subTags.unshiftObject(generalTag);
        }
        tag.set('options', subTags);
        tag.set('groupName', { title: tag.get('title'), name: tag.get('displayName'), type: 'tag' });
      } else {
        tag.set('disabled', disabled);
      }

      if (depth === 0) {
        res.push(tag);
      }
    },

    presetMessageSetter: on('init', observer('selectedFeedback.preset_message', 'selectedFeedback.messagesOrderedByDate', function () {
      if (this.get('selectedFeedback.source') == "trustpilot_review" && this.get("selectedFeedback.messagesOrderedByDate").filterBy('from_client', false)[0]) {
        this.set('newMessageText', this.get("selectedFeedback.messagesOrderedByDate").filterBy('from_client', false)[0].get('text'));
        return;
      }
      this.set('newMessageText', this.get('selectedFeedback.preset_message'));
    })),

    reloadPresetMessage: observer('selectedFeedback.preset_message', 'selectedFeedback.messages.length', function () {
      if (this.get('selectedFeedback.source') == "trustpilot_review") {
        return;
      }
      if (this.get('selectedFeedback.messages').filterBy('from_client', false).get('length') >= 1) {
        this.set('newMessageText', '');
      } else {
        this.set('newMessageText', this.get('selectedFeedback.preset_message'));
      }
    }),

    isPublic: computed('currentCompany.public_feedback_default', 'selectedFeedback.is_public', function () {
      var is_public = this.get('selectedFeedback.is_public');
      return is_public === null ? this.get('currentCompany.public_feedback_default') : is_public;
    }),

    ExternalLinkSetter: on('init', observer('selectedFeedback.id', function () {
      var _this4 = this;

      this.set('ExternalLink', null);
      if (!this.get('isLoading')) {
        this.get('selectedFeedback.company_segment_values').then(function (ans_set_seg) {
          _ember['default'].RSVP.all(ans_set_seg.map(function (ass) {
            return ass.get('segment');
          })).then(function () {
            if (_this4.get('selectedFeedback.source') === 'facebook_review') {
              _this4.set('IntegrationLink', _this4.get('i18n').t('chats.open_in', { inte: 'Facebook' }).toString());
              _this4.setFacebookLink(ans_set_seg);
            } else if (_this4.get('selectedFeedback.source') === 'trustpilot_review') {
              _this4.set('IntegrationLink', _this4.get('i18n').t('chats.open_in', { inte: 'Trustpilot' }).toString());
              _this4.set('ExternalLink', _dueDashboardConfigEnvironment['default'].EmberENV.TRUSTPILOT_REVIEW_URL + '/' + _ember['default'].get(ans_set_seg.filterBy('segment.name', 'trustpilot_id')[0] || {}, 'value'));
            } else {
              _this4.set('IntegrationLink', _this4.get('i18n').t('chats.open_in', { inte: 'Aircall' }).toString());
              _this4.set('ExternalLink', _ember['default'].get(ans_set_seg.filterBy('segment.name', 'direct_link')[0] || {}, 'value'));
            }
          });
        });
      }
    })),

    newMessObs: observer('newMessageText', function () {
      var text_area = this.$('#response textarea');
      if (text_area && text_area[0]) {
        text_area.val(this.get('newMessageText'));
      }
    }),

    canEditFeedback: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'annotate_and_delete'].includes(this.get('currentAccount.rights').get('chats_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canAnnotateFeedback: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'annotate', 'write', 'annotate_and_delete'].includes(this.get('currentAccount.rights').get('chats_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canAnswerFeedback: computed('currentAccount.content.profile', function () {
      var profile = this.get('currentAccount.content.profile');
      switch (profile) {
        case 'admin':
        case 'manager':
          return true;
        case 'custom':
          return ['all', 'write'].includes(this.get('currentAccount.rights').get('chats_rights'));
        case 'read_only':
          return false;
        default:
          return false;
      }
    }),

    canUseTags: computed('currentAccount.canUseTags', function () {
      return this.get('currentAccount.canUseTags');
    }),

    canCloseTags: computed('currentAccount.canUseTags', 'currentAccount.isFromChina', 'selectedFeedback.source_zone', function () {
      return this.get('currentAccount.canUseTags') && (this.get('currentAccount.isFromChina') || this.get('selectedFeedback.source_zone') !== 'china' && !this.get('currentAccount.isFromChina'));
    }),

    canCloseAutoTags: computed('currentAccount.canUseAutoTags', 'currentAccount.isFromChina', 'selectedFeedback.source_zone', function () {
      return this.get('currentAccount.canUseAutoTags') && (this.get('currentAccount.isFromChina') || this.get('selectedFeedback.source_zone') !== 'china' && !this.get('currentAccount.isFromChina'));
    }),

    resolvedBtnClass: computed('selectedFeedback.resolved', function () {
      return !this.get('selectedFeedback.resolved') ? 'new-2020-btn' : 'new-2020-btn green';
    }),

    setIntegrationImageSet: observer('selectedFeedback.from_external', function () {
      var _this5 = this;

      if (this.get('selectedFeedback.from_external')) {
        var stored = this.store.peekAll('integration').findBy('name', this.get('selectedFeedback.source').dasherize());
        if (stored) {
          this.set('integrationImage', stored);
        } else {
          return this.store.findAll('integration').then(function (records) {
            _this5.set('integrationImage', records.findBy('name', _this5.get('selectedFeedback.source').dasherize()));
          });
        }
      } else {
        this.set('integrationImage', null);
      }
    }),

    noManualTags: computed('selectedFeedback.feedback_tags', function () {
      var fdbTags = this.get('selectedFeedback.feedback_tags');
      return !fdbTags.any(function (ft) {
        return !ft.get('from_due');
      });
    }),

    noLiveAutoTags: computed('selectedFeedback.feedback_tags', function () {
      var fdbTags = this.get('selectedFeedback.feedback_tags');
      return !fdbTags.any(function (ft) {
        return ft.get('from_due');
      });
    }),

    updateList: function updateList() {
      var feedback = this.get('selectedFeedback');
      var filter = this.get('filter.status');
      if (filter === "new" && feedback.get('messages').filterBy('from_client', false).length > 0 || filter === "new" && feedback.get('resolved') || filter === 'ongoing' && feedback.get('resolved') || filter === 'resolved' && !feedback.get('resolved') || filter === 'deleted' && feedback.get('status') !== 'deleted' || filter !== 'deleted' && feedback.get('status') === 'deleted') {
        this.get('removeFeedbackFromList')(feedback);
      } else if (filter === "resolved" && feedback.get('resolved') || filter === "ongoing" && !feedback.get('resolved') || filter === "new" && !feedback.get('resolved') || filter === 'deleted' && feedback.get('status') === 'deleted' || filter !== 'deleted' && feedback.get('status') !== 'deleted') {
        this.get('addFeedbackToList')(feedback);
      }
      this.get('updateStatusCounter')(feedback.get('chat_status'), this.get('old_status'));
      this.set('old_status', feedback.get('chat_status'));
    },

    feedback_old_status: on('init', observer('selectedFeedback.id', function () {
      this.set('old_status', this.get('selectedFeedback.chat_status'));
    })),

    feedbackSegmentsNotHidden: computed.filterBy('selectedFeedback.company_segment_values', 'isHidden', false),

    feedbackSegmentsToDisplay: computed('feedbackSegmentsNotHidden', 'segmentsToDisplay', 'segmentsToDisplay.[]', function () {
      var displayedSegmentsIds = this.get('segmentsToDisplay').mapBy('id');
      return this.get('feedbackSegmentsNotHidden').filter(function (segment) {
        return displayedSegmentsIds.contains(segment.get('segment.id'));
      });
    }),

    feedbackSegmentSorting: ['segment.name'],
    selectedFeedbackSegments: computed.sort('feedbackSegmentsToDisplay', 'feedbackSegmentSorting'),

    selectedFeedbackSegmentsCategories: computed('selectedFeedbackSegments', function () {
      var categories = this.get('selectedFeedbackSegments').map(function (csv) {
        return csv.get('category');
      });
      categories = [].concat(_toConsumableArray(new Set(categories)));
      categories = categories.filter(function (n) {
        return n;
      });
      return categories.sort();
    }),

    noCategory: computed('selectedFeedbackSegments', function () {
      var emptyCategories = this.get('selectedFeedbackSegments').filter(function (csv) {
        return !csv.get('category');
      });
      return emptyCategories.length > 0 && this.get('selectedFeedbackSegmentsCategories').length > 0;
    }),

    selectedFeedbackUpdatedAtDate: computed('selectedFeedback.chat_updated_at', function () {
      return (0, _moment['default'])(this.get('selectedFeedback.chat_updated_at')).format('L');
    }).readOnly(),

    selectedFeedbackUpdatedAtTime: computed('selectedFeedback.chat_updated_at', function () {
      return (0, _moment['default'])(this.get('selectedFeedback.chat_updated_at')).format('LT');
    }).readOnly(),

    setFacebookLink: function setFacebookLink(ans_set_seg) {
      var user_id = ans_set_seg.findBy('segment.name', '.reviewer_id').get('value');
      var fb_review_id = ans_set_seg.findBy('segment.name', '.review_id').get('value');
      this.set('ExternalLink', 'https://www.facebook.com/' + user_id + '/activity/' + fb_review_id);
    },

    fromTrustpilot: computed('selectedFeedback.source', function () {
      return this.get('selectedFeedback.source') == "trustpilot_review" ? true : false;
    }),

    trustpilotStyle: computed('fromTrustpilot', function () {
      return this.get('fromTrustpilot') ? "trustpilotStyle" : "";
    }),

    canEmailResponder: computed('selectedFeedback.title', function () {
      return this.validateEmail(this.get('selectedFeedback.title'));
    }),

    messagesOrderedByDateWithoutNotes: _ember['default'].computed.filterBy('selectedFeedback.messagesOrderedByDate', 'is_note', false),

    answerText: computed('selectedFeedback.source', 'messagesOrderedByDateWithoutNotes', function () {
      // if google feedback is already resolved, show "Update in google", but only if more than 1 messages
      if (this.get('selectedFeedback.source') == 'google_my_business' && this.get('selectedFeedback.resolved')) {
        if (this.get("messagesOrderedByDateWithoutNotes").length > 1) {
          this.set('sendResolveText', 'chats.google_update');
        } else {
          this.set('sendResolveText', 'chats.google_answer');
        }
      } else {
        this.set('sendResolveText', 'chats.answer_and_set_resolved');
      }

      if (this.get('selectedFeedback.source') == "trustpilot_review" || this.get('selectedFeedback.source') == "google_my_business") {
        var key = this.get('selectedFeedback.source').split('_')[0];
        if (this.get("messagesOrderedByDateWithoutNotes").length > 1) {
          this.set('sendText', 'chats.' + key + '_update');
          return 'chats.' + key + '_update';
        } else {
          this.set('sendText', 'chats.' + key + '_answer');
          return 'chats.' + key + '_answer';
        }
      }
      this.set('sendText', 'chats.send');
      return 'chats.answer';
    }),

    // unused ?
    replyActions: computed('selectedFeedback.source', 'selectedFeedback.messagesOrderedByDate[]', function () {
      if (this.get('selectedFeedback.source') == "trustpilot_review") {
        if (this.get("selectedFeedback.messagesOrderedByDate").length > 1) {
          this.set('answerText', 'chats.trustpilot_update');
          this.set('newMessageText', this.get("selectedFeedback.messagesOrderedByDate")[1].get('text'));
          return [{ icon: 'send', action: 'setResolvedAndSendMessage', textKey: 'chats.trustpilot_update_and_set_resolved' }, { icon: 'send', action: 'sendMessage', textKey: 'chats.trustpilot_update' }, { icon: 'check', action: 'setResolved', textKey: 'chats.set_resolved' }];
        } else {
          this.set('answerText', 'chats.trustpilot_answer');
          this.set('newMessageText', this.get("selectedFeedback.messagesOrderedByDate")[1].get('text'));
          return [{ icon: 'send', action: 'setResolvedAndSendMessage', textKey: 'chats.trustpilot_answer_and_set_resolved' }, { icon: 'send', action: 'sendMessage', textKey: 'chats.trustpilot_answer' }, { icon: 'check', action: 'setResolved', textKey: 'chats.set_resolved' }];
        }
      }
      this.set('answerText', 'chats.answer');
      return [{ icon: 'send', action: 'setResolvedAndSendMessage', textKey: 'chats.answer_and_set_resolved' }, { icon: 'send', action: 'sendMessage', textKey: 'chats.answer' }, { icon: 'check', action: 'setResolved', textKey: 'chats.set_resolved' }];
    }),

    createMessage: function createMessage(isNote) {
      var _this6 = this;

      if (this.get('selectedFeedback.source') === 'trustpilot_review' && !isNote && this.get("selectedFeedback.messagesOrderedByDate").filter(function (one) {
        return one.get('from_client') == false;
      }).length > 0) {
        if (!confirm(this.get('i18n').t('settings.integrations.trustpilot.review.reply_alert'))) {
          return;
        }
      }
      var feedback = this.get('selectedFeedback');
      var msg = this.$('.chat-response-message textarea').val();
      // TODO: clean/remove unused code unread
      var message = this.store.createRecord('message', {
        feedback: feedback,
        from_client: false,
        unread: false,
        text: msg,
        is_note: isNote
      });
      this.set('promise', message.save()).then(function () {
        _this6.get('store').findRecord('feedback', feedback.id, { reload: true }).then(function (reloadedFb) {
          _this6.set('selectedFeedback', reloadedFb);
          _this6.set('error', null);
          if (_this6.get('selectedFeedback.source') != 'trustpilot_review') {
            _this6.$('.chat-response-message textarea').val('');
          }
          if (isNote && reloadedFb.get('company.open_feedback_on_note_creation')) {
            _this6.updateList();
          }
        });
      })['catch'](function (e) {
        message.unloadRecord();
        _this6.set('error', e.errors.get('firstObject.detail'));
      });
    },

    resolveAndSendMessage: function resolveAndSendMessage() {
      var _this7 = this;

      this.createMessage(false);
      this.get('promise').then(function () {
        _this7.set('selectedFeedback.resolved', true);
        _this7.get('selectedFeedback').save().then(function (fb) {
          if (!fb.get('resolved') || fb.get('resolver_account.email')) return _this7.set('selectedFeedback', fb);
          _this7.get('store').findRecord('feedback', fb.get('id'), { reload: true }).then(function (reloadedFb) {
            return _this7.set('selectedFeedback', reloadedFb);
          });
        });
      });
    },

    deleteFeedbackTag: function deleteFeedbackTag(tag) {
      var _this8 = this;

      this.store.query('feedback-tag', { filter: { tag_id: tag.id, feedback_id: this.get('selectedFeedback.id') } }).then(function (fdbTags) {
        var promises = [];
        if (_this8.get('auto_tag_hard_delete')) {
          fdbTags.forEach(function (fdbTag) {
            fdbTag.set('hard_delete', true);
            promises.push(fdbTag.save().then(function (fb_tag) {
              return fb_tag.unloadRecord();
            }));
          });
        } else {
          fdbTags.forEach(function (fdbTag) {
            return promises.push(fdbTag.destroyRecord());
          });
        }
        RSVP.all(promises).then(function () {
          _this8.get('selectedFeedback.tags').removeObject(tag);
          _this8.notifyPropertyChange('selectedFeedback.tags');
          _this8.notifyPropertyChange('selectedFeedback.feedback_tags');
        });
      });
    },

    closeModal: function closeModal() {
      this.set('modalIsOpened', false);
    },

    openResolutionModal: function openResolutionModal() {
      this.set('resolutionModalIsOpen', true);
    },

    resolveFeedback: function resolveFeedback() {
      var _this9 = this;

      this.set('resolutionModalIsOpen', false);
      this.get('selectedFeedback').toggleProperty('resolved');
      this.get('selectedFeedback').save().then(function (fb) {
        if (!fb.get('resolved') || fb.get('resolver_account.email')) return _this9.set('selectedFeedback', fb);
        _this9.set('resolutionModalIsOpen', false);
        _this9.get('store').findRecord('feedback', fb.get('id'), { reload: true }).then(function (reloadedFb) {
          return _this9.set('selectedFeedback', reloadedFb);
        });
      }).then(function () {
        return _this9.updateList();
      });
    },

    selectedFeedbackRootCauses: computed('selectedFeedback.feedback_tags.@each.tag', function () {
      if (!this.get('selectedFeedback.feedback_tags')) {
        return [];
      }
      return this.get('selectedFeedback.feedback_tags').filter(function (fbt) {
        return fbt.get('tag.kind') === 'root_cause';
      });
    }),

    selectedFeedbackActionsTaken: computed('selectedFeedback.feedback_tags.@each.tag', function () {
      if (!this.get('selectedFeedback.feedback_tags')) {
        return [];
      }
      return this.get('selectedFeedback.feedback_tags').filter(function (fbt) {
        return fbt.get('tag.kind') === 'action_taken';
      });
    }),

    selectedFeedbackTags: computed('selectedFeedback.feedback_tags.@each.tag', function () {
      if (!this.get('selectedFeedback.feedback_tags')) {
        return [];
      }
      return this.get('selectedFeedback.feedback_tags').filter(function (fbt) {
        return fbt.get('tag.kind') === 'tag';
      });
    }),

    pushActionTaken: function pushActionTaken(action) {
      this.get('selectedFeedback.actions_taken').pushObject(action);
      this.notifyPropertyChange('selectedFeedback');
    },

    deleteActionTaken: function deleteActionTaken(action) {
      this.get('selectedFeedback.actions_taken').removeObject(action);
      this.notifyPropertyChange('selectedFeedback');
    },

    validateEmail: function validateEmail(email) {
      return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },

    setSegmentCoockies: function setSegmentCoockies() {
      var cookieService = localStorage;
      cookieService.setItem(this.get('_cookieKey'), this.get('showSegments'));
    },

    actions: {
      openModal: function openModal() {
        this.set('modalIsOpened', true);
      },

      closeModal: function closeModal() {
        this.closeModal();
      },

      switchToNote: function switchToNote(isNote) {
        this.set('note', isNote);
      },

      togglePublic: function togglePublic() {
        this.set('selectedFeedback.is_public', !this.get('selectedFeedback.isPublic'));
        this.get('selectedFeedback').save();
      },

      deleteFeedback: function deleteFeedback() {
        if (window.confirm(this.get('i18n').t('chats.delete').string)) {
          var selectedFeedback = this.get('selectedFeedback');
          selectedFeedback.set('status', 'deleted');
          selectedFeedback.save();
          this.updateList();
        }
      },

      resolveFeedbackAction: function resolveFeedbackAction() {
        if (!this.get('selectedFeedback.resolved') && this.get('currentCompany.modal_resolution_enabled') && !this.get('resolutionModalIsOpen') && (this.get('currentCompany.enable_new_survey_dashboard') || !this.get('currentCompany.enable_new_survey_dashboard') && (this.get('currentCompany.resolution_popup_for_detractor') && this.get('selectedFeedback.rating.score') <= 6 || !this.get('currentCompany.resolution_popup_for_detractor')))) {
          this.openResolutionModal();
        } else {
          this.resolveFeedback();
        }
      },

      actionTakenUpdated: function actionTakenUpdated(action) {
        this.pushActionTaken(action);
      },

      removeActionTaken: function removeActionTaken(action) {
        this.deleteActionTaken(action);
      },

      cancelCallback: function cancelCallback() {
        this.set('resolutionModalIsOpen', false);
      },

      openDirectLink: function openDirectLink() {
        var url = this.get('ExternalLink');
        window.open(url);
      },

      copyLink: function copyLink() {
        navigator.clipboard.writeText(this.get('selectedFeedback.chat_url'));
      },

      restoreFeedback: function restoreFeedback() {
        var selectedFeedback = this.get('selectedFeedback');
        selectedFeedback.set('status', 'live');
        selectedFeedback.save();
        this.updateList();
      },

      showSatisfaction: function showSatisfaction() {
        if (!this.get('showSatisfaction')) {
          this.set('showSatisfaction', true);
        } else {
          this.set('showSatisfaction', false);
          var $elem = this.$('.satisfaction-modal')[0];
          if ($elem.classList.contains('hidden')) {
            $elem.classList.remove('hidden');
          } else {
            $elem.classList.add('hidden');
          }
        }
      },

      sendMessage: function sendMessage(isNote) {
        var feedback = this.get('selectedFeedback');
        if (this.get('currentCompany.verbatim_auto_translation') && !isNote) {
          if (window.confirm(this.get('i18n').t('chats.language_confirmation', { language: htmlSafe(feedback.get('chosen_language.content.name')) }))) {
            this.createMessage(isNote);
          } else {
            this.set('promise', Promise.resolve());
          }
        } else {
          this.createMessage(isNote);
        }
      },

      sendAndResolve: function sendAndResolve() {
        var feedback = this.get('selectedFeedback');
        if (this.get('currentCompany.verbatim_auto_translation')) {
          if (window.confirm(this.get('i18n').t('chats.language_confirmation', { language: htmlSafe(feedback.get('chosen_language.content.name')) }))) {
            this.resolveAndSendMessage();
          } else {
            this.set('promise', Promise.resolve());
          }
        } else {
          this.resolveAndSendMessage();
        }
      },

      removeTagAction: function removeTagAction(tag) {
        this.deleteFeedbackTag(tag);
      },

      closeDropdown: function closeDropdown(kind) {
        var _this10 = this;

        this.get('tagManager').getAll({ survey: this.get('selectedFeedback.survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }, kind).then(function (tags) {
          if (kind === 'root_cause') {
            _this10.set('rootCauses', tags);
          } else if (kind === 'action_taken') {
            _this10.set('actionsTaken', tags);
          } else {
            _this10.set('tags', tags);
          }
        });
      },

      selectTag: function selectTag(kind, tagObject, feeling) {
        var selectedTag = this.get('selectedFeedback.tags');
        var tag = tagObject.realTag || tagObject;
        this.store.createRecord('feedback-tag', {
          tag: tag,
          feedback: this.get('selectedFeedback'),
          feeling: ['root_cause', 'action_taken'].includes(kind) ? null : feeling
        }).save();
        selectedTag.pushObject(tag);
      },

      searchTags: function searchTags(kind, term) {
        var _this11 = this;

        this.set('flatLevelDisplay', term !== '');
        this.get('tagManager').getAll({ name: term, survey: this.get('selectedFeedback.survey.id'), dropdown_list_restrictions: ['hidden', 'none', 'read'] }, kind).then(function (tags) {
          if (kind === 'root_cause') {
            _this11.set('rootCauses', tags);
          } else if (kind === 'action_taken') {
            _this11.set('actionsTaken', tags);
          } else {
            _this11.set('tags', tags);
          }
        });
      },

      mailResponder: function mailResponder() {
        var email = this.get('selectedFeedback.title');
        if (this.validateEmail(email)) {
          window.open("mailto:" + email + "?body=" + encodeURIComponent(this.get('selectedFeedback.preset_message')));
        }
      },

      toggleShowSegments: function toggleShowSegments(value) {
        this.set('showSegments', value);
        this.setSegmentCoockies();
      }
    }
  });
});