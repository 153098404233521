define('due-dashboard/controllers/sso-login', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/helpers/email-validator'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardHelpersEmailValidator) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    currentAccount: _ember['default'].inject.service(),

    password: '',
    identification: '',
    minError: false,

    loading: true,
    displayEmailValidation: false,
    displayPasswordValidation: false,
    lastError: null,
    ssoError: undefined,
    validEmail: false,
    validPassword: false,
    session: _ember['default'].inject.service(),

    init: function init() {
      var _this = this;

      this.set('currentAccount.newCompany', false);
      this._super.apply(this, arguments);

      function parseHashFor(name) {
        var locationHash = document.location.hash.substr(1);
        return locationHash.substr(locationHash.indexOf(name + '=')).split('&')[0].split('=')[1];
      }

      // saml token login
      var errorValue = parseHashFor('error');
      var loginValue = parseHashFor('login');
      var tokenValue = parseHashFor('access_token');

      if (loginValue !== undefined) {
        this.set('identification', loginValue);
      }

      if (errorValue !== undefined) {
        this.set('ssoError', 'sso_error_' + parseInt(errorValue));
        this.set('loading', false);
      } else if (tokenValue !== undefined) {
        this.get('session').authenticate('authenticator:access-token', { access_token: tokenValue }).then(function () {
          _this.transitionTo('companies.index');
        })['catch'](function (error) {
          // Do not remove and do not ask why
          console.log("error", error);
          this.set('minError', true);
          this.set('loading', false);
        });
      } else {
        // enable input and connect button
        this.set('loading', false);
      }
    },

    disableButton: computed('loading', 'identification', function () {
      return this.get('loading') || !_dueDashboardHelpersEmailValidator['default'].compute(this.get('identification'));
    }),

    samlUrl: computed(function () {
      return _dueDashboardConfigEnvironment['default'].EmberENV.SAML_SSO_URL;
    }),

    errorMessage: computed('lastError.errors', 'i18n.locale', function () {
      var error = this.get('lastError.errors');
      if (error !== undefined && error[0].detail === 'failed_login') {
        return this.get('i18n').t('errors.' + error[0].detail);
      }
      return error ? this.get('i18n').t('errors.' + error[0].detail) : null;
    }),

    actions: {
      minifyError: function minifyError() {
        this.set('minError', true);
      },

      emailFocusedOut: function emailFocusedOut() {
        this.set('displayEmailValidation', true);
      }
    }

  });
});