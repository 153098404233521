define('due-dashboard/components/create-profile', ['exports', 'ember', 'due-dashboard/models/segment'], function (exports, _ember, _dueDashboardModelsSegment) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var computed = _ember['default'].computed;
  var _Ember$computed = _ember['default'].computed;
  var alias = _Ember$computed.alias;
  var filterBy = _Ember$computed.filterBy;
  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = Component.extend({
    classNames: ['create-profile'],
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    questionsRightsEnum: [false, true],
    segmentSelection: computed(function () {
      return [];
    }),
    selectedSegments: computed(function () {
      return [];
    }),
    scenariosRightsEnum: alias('questionsRightsEnum'),
    analysisRightsEnum: ['none', 'read'],
    verbatimsRightsEnum: ['none', 'read'],
    usersRightsEnum: ['none', 'manage'],
    userRightsManagementEnum: ['create', 'update', 'delete'],
    chatsRightsEnum: ['none', 'read', 'annotate', 'annotate_and_delete', 'write', 'all'],
    surveysRightsEnum: ['none', 'read', 'write', 'distribute', 'all'],
    exportsRightsEnum: ['none', 'all'],
    templatesRightsEnum: ['none', 'read', 'write', 'all'],
    graphsRightsEnum: ['none', 'read', 'all'],
    rating_scalesRightsEnum: ['none', 'read', 'all'],
    fontsRightsEnum: ['none', 'read', 'all'],
    custom_reportsRightsEnum: ['none', 'read'],
    existingsRights: computed('company', function () {
      var perms = ['graphs', 'rating_scales', 'fonts', 'questions', 'custom_reports', 'exports', 'chats', 'surveys', 'templates', 'scenarios', 'users'];
      if (this.get('company.show_analysis')) {
        perms.push('analysis');
      }
      if (this.get('company.show_verbatim')) {
        perms.push('verbatims');
      }
      return perms;
    }),
    DISABLED_SEGMENT_FILTERS: ['MC_CID', 'MC_EID', 'SPM_KEY', 'UTM_SOURCE', 'UTM_CAMPAIGN', 'UTM_CONTENT', 'UTM_MEDIUM', 'UTM_TERM', 'SCHEDULED_AT'],
    existingsActionRights: ['tags', // private tags
    'auto_tags' // auto tags
    ],
    tagsRightsEnum: ['none', 'read', 'use', 'write'],
    auto_tagsRightsEnum: [// _ because of the serialization
    'hidden', 'read', 'assign'],

    _surveyFilter: filterBy('profile.feedback_filters', 'type', 'match_surveys_feedback_filter'),
    surveyFilter: alias('_surveyFilter.firstObject'),
    filterSurveys: alias('surveyFilter.filter_surveys'),

    canManageUsers: computed('profile.users_rights', function () {
      return this.get('profile.users_rights') === 'manage';
    }),

    surveySetter: (function () {
      var _this = this;

      this.store.query('survey', { filter: { status: 'live,archived' } }).then(function (surveys) {
        if (_this.isDestroyed) {
          return;
        }
        _this.setProperties({ surveys: surveys });
      });
      var profile = this.get('profile');
      if (profile) {
        profile.get('feedback_filters').then(function (ff) {
          ff = ff.filterBy('type', 'match_segment_and_values_feedback_filter');
          RSVP.all(ff.mapBy('segment')).then(function (segments) {
            segments.forEach(function (s) {
              _this.get('segmentSelection').addObject(s);
              _this.get('selectedSegments').addObject(s);
            });
            _this.updateSegmentValues(ff);
          });
        });
      }
    }).on('didReceiveAttrs'),

    updateSegmentValues: function updateSegmentValues(filters) {
      var _this2 = this;

      var values_and_op = {};
      RSVP.all(filters.map(function (filter) {
        return filter.get('match_segment_and_values_feedback_filters_values');
      })).then(function () {
        filters.forEach(function (filter) {
          return values_and_op[filter.get('segment.id')] = {
            op: filter.get('operator'),
            values: filter.get('filter_values').toArray(),
            delegate_to_user: filter.get('delegate_to_user')
          };
        });
        if (!_this2.isDestroyed) {
          _this2.set('baseValuesAndOp', values_and_op);
        }
      });
    },

    profiles: computed('profile', 'filterSurveys.length', function () {
      var _this3 = this;

      return this.get('existingsRights').map(function (right) {
        var disabled = [];
        if (right === 'surveys' && _this3.get('filterSurveys.length')) {
          disabled = ['all'];
          if (_this3.get('profile.surveys_rights') === 'all') {
            _this3.set('profile.surveys_rights', 'write');
          }
        }
        return _ember['default'].Object.create({
          profile: _this3.get('profile'),
          selected: alias('profile.' + right + '_rights'),
          label: 'settings.users.new_profile.access_rights.' + right + '.title',
          tooltip: 'settings.users.new_profile.access_rights.' + right + '.tooltip',
          options: _this3.get(right + 'RightsEnum'),
          option_translation: 'settings.users.new_profile.access_rights.' + right + '.',
          disabled: disabled
        });
      });
    }),

    userRightsManagement: computed('profile', function () {
      var disabled = [];
      if (this.get('profile.user_rights_management') === null) {
        this.set('profile.user_rights_management', []);
      }
      return _ember['default'].Object.create({
        profile: this.get('profile'),
        selected: alias('profile.user_rights_management'),
        label: 'settings.users.new_profile.access_rights.users.user_rights_management.title',
        tooltip: 'settings.users.new_profile.access_rights.users.user_rights_management.tooltip',
        roles_tooltip: 'settings.users.new_profile.access_rights.users.user_rights_management.roles_tooltip',
        options: this.get('userRightsManagementEnum'),
        option_translation: 'settings.users.new_profile.access_rights.users.user_rights_management.',
        disabled: disabled
      });
    }),

    actionsRights: computed('profile', function () {
      var _this4 = this;

      return this.existingsActionRights.map(function (right) {
        var disabled = [];
        return _ember['default'].Object.create({
          profile: _this4.get('profile'),
          selected: alias('profile.' + right + '_rights'),
          label: 'settings.users.new_profile.access_rights.' + right + '.title',
          tooltip: 'settings.users.new_profile.access_rights.' + right + '.tooltip',
          options: _this4.get(right + 'RightsEnum'),
          option_translation: 'settings.users.new_profile.access_rights.' + right + '.',
          disabled: disabled
        });
      });
    }),

    availableReports: computed('allReports', 'userReportsRights.@each', 'displayedSelectedReports', function () {
      var selectedReportRights = this.get('selectedReportRights');
      var displayedSelectedReports = this.get('displayedSelectedReports');

      if (!this.get('allReports') || !displayedSelectedReports) {
        return [];
      }
      var availableReports = this.get('allReports').map(function (report) {
        if (displayedSelectedReports && displayedSelectedReports.filter(function (r) {
          return r.permalink === report.get('permalink');
        }).length === 0 && report.get('permalink')) {
          var displayName = report.get('permalink').replace(/_/g, ' ');
          displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
          return _extends({}, report, { displayName: displayName });
        } else if (displayedSelectedReports && displayedSelectedReports.filter(function (r) {
          return r.permalink === report.get('permalink');
        }).length === 0 && report.get('id')) {
          return _extends({}, report, { displayName: report.get('id') });
        }
      });

      return availableReports.filter(function (ar) {
        return ar != undefined;
      });
    }),

    whitelistedCustomRoles: computed('profile.limit_user_management_by_custom_roles.@each.whitelisted_custom_role', function () {
      return this.get('profile.limit_user_management_by_custom_roles').mapBy('whitelisted_custom_role');
    }),

    availableRoles: computed('allRoles', 'whitelistedCustomRoles', function () {
      var selectedRoles = this.get('whitelistedCustomRoles');
      var allRoles = this.get('allRoles');

      if (!allRoles || !selectedRoles) {
        return [];
      }

      var selectedRoleIds = selectedRoles.map(function (role) {
        return role.get('id');
      });
      return allRoles.filter(function (role) {
        return !selectedRoleIds.includes(role.get('id'));
      });
    }),

    selectedReportRights: computed('userReportsRights.@each', 'userReportsRights', function () {
      var _this5 = this;

      if (!this.get('userReportsRights')) {
        return [];
      }
      var reports = [];
      if (this.get('userReportsRights.length') === 0) {
        this.set('displayedSelectedReports', []);
      }
      return this.get('userReportsRights').map(function (reportRight) {
        reportRight.get('report').then(function (report) {
          if (report.get('permalink')) {
            var displayName = report.get('permalink').replace(/_/g, ' ');
            displayName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
            reports.push(_extends({}, reportRight, { permalink: report.get('permalink'), displayName: displayName }));
            _this5.set('displayedSelectedReports', reports);
          }
        });
      });
    }),

    dueInputProps: computed('i18n.locale', function () {
      return {
        placeholder: ''
      };
    }),

    init: function init() {
      var _this6 = this;

      var registerApi = this.get('registerApi');
      if (registerApi) {
        registerApi({ reloadSurvey: function reloadSurvey() {
            return _this6.send('reloadProfile');
          } });
      }
      this.store.query('report-custom-right', { filter: { account_custom_profile_right_id: this.get('profile.id') } }).then(function (reportsRights) {
        return _this6.set('userReportsRights', reportsRights.toArray());
      });
      this.store.findAll('account_custom_profile_right').then(function (roles) {
        return _this6.set('allRoles', roles);
      });
      this.store.findAll('report').then(function (reports) {
        return _this6.set('allReports', reports);
      });
      this.store.query('segment', { filter: { display: 'filter' } }).then(function (allSegments) {
        return !_this6.isDestroyed && _this6.set('allSegments', allSegments);
      });
      this._super.apply(this, arguments);
    },

    enabledSegments: computed.filter('allSegments', function (s) {
      return !this.DISABLED_SEGMENT_FILTERS.includes(s.get('name').toUpperCase());
    }),

    sortedEnabledSegments: computed.sort('enabledSegments.[]', _dueDashboardModelsSegment['default'].sort),

    willDestroyElement: function willDestroyElement() {
      this.set('segmentSelection', []);
      this.set('selectedSegments', []);
    },

    _searchSurvey: function _searchSurvey(term, resolve, reject) {
      this.store.query('survey', {
        filter: { name: term, status: 'live,archived' },
        page: { number: 1, size: 50 }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },

    _searchReport: function _searchReport(term, resolve, reject) {
      var _this7 = this;

      this.store.query('report', {
        filter: { name: term },
        page: { number: 1, size: 50 }
      }).then(function (allReports) {
        return _this7.set('allReports', allReports);
      }, reject);
    },

    _searchCustomRole: function _searchCustomRole(term, resolve, reject) {
      var _this8 = this;

      this.store.query('account_custom_profile_right', {
        filter: { name: term },
        page: { number: 1, size: 50 }
      }).then(function (allRoles) {
        return _this8.set('allRoles', allRoles);
      }, reject);
    },

    _createReportCustomRight: function _createReportCustomRight(report) {
      var _this9 = this;

      this.store.createRecord('report-custom-right', {
        report: report,
        account_custom_profile_right: this.get('profile')
      }).save().then(function (rcr) {
        _this9.get('userReportsRights').addObject(rcr);
      });
    },

    actions: {
      reloadProfile: function reloadProfile() {
        this.set('segmentSelection', []);
        this.set('selectedSegments', []);
        this.surveySetter();
      },

      addWhitelistedRoleToProfile: function addWhitelistedRoleToProfile(role) {
        var profile = this.get('profile');
        var limitUserManagementByCustomRoles = profile.get('limit_user_management_by_custom_roles');

        if (limitUserManagementByCustomRoles) {
          var customRole = this.store.createRecord('limit-user-management-by-custom-role', {
            custom_role: profile,
            whitelisted_custom_role: role
          });
          this.get('profile.limit_user_management_by_custom_roles').addObject(customRole);
        }
      },

      deleteWhitelistedRoleFromProfile: function deleteWhitelistedRoleFromProfile(role) {
        var profile = this.get('profile');
        var limitUserManagementByCustomRoles = profile.get('limit_user_management_by_custom_roles');
        var customRole = limitUserManagementByCustomRoles.findBy('whitelisted_custom_role.id', role.get('id'));
        limitUserManagementByCustomRoles.removeObject(customRole);
        if (customRole.get('isNew')) {
          customRole.unloadRecord();
        } else {
          customRole.deleteRecord();
        }
      },

      searchSurveys: function searchSurveys(term) {
        var _this10 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this10, _this10._searchSurvey, term, resolve, reject, 600);
        });
      },

      searchReports: function searchReports(term) {
        var _this11 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this11, _this11._searchReport, term, resolve, reject, 600);
        });
      },

      searchCustomRoles: function searchCustomRoles(term) {
        var _this12 = this;

        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _ember['default'].run.debounce(_this12, _this12._searchCustomRole, term, resolve, reject, 600);
        });
      },

      mutFilterSurveys: function mutFilterSurveys(surveys) {
        if (!this.get('filterSurveys')) {
          var new_survey_filter = this.store.createRecord('match-surveys-feedback-filter', { profile: this.get('profile') });
          this.get('profile.feedback_filters').addObject(new_survey_filter);
        }
        this.get('surveyFilter').updateSurveys(surveys);
      },

      mutFilterSegment: function mutFilterSegment(segment, values, operator, delegate_to_user) {
        var fb_filter = this.get('profile.feedback_filters').findBy('segment.id', segment.get('id'));
        fb_filter.set('operator', operator);
        fb_filter.set('delegate_to_user', delegate_to_user);
        this.updateSegmentValues([fb_filter]);
        fb_filter.updateValues(values);
      },

      addFilterClick: function addFilterClick() {
        this.set('showSegmentsDropdown', true);
        this.get('segmentsDropDownApi').actions.open();
      },

      removeCustomFilter: function removeCustomFilter(segment) {
        this.get('segmentSelection').removeObject(segment);
        var to_remove = this.get('profile.feedback_filters').findBy('segment.id', segment.get('id'));
        this.get('profile.feedback_filters').removeObject(to_remove);
        this.get('selectedSegments').removeObject(segment);
        to_remove.deleteRecord();
      },

      addCustomFilter: function addCustomFilter(segment) {
        this.get('segmentSelection').addObject(segment);
        var new_survey_filter = this.store.createRecord('match-segment-and-values-feedback-filter', { profile: this.get('profile'), segment: segment });
        this.get('profile.feedback_filters').addObject(new_survey_filter);
        this.get('selectedSegments').addObject(segment);
      },

      toggleAccess: function toggleAccess() {
        this.toggleProperty('profile.has_access_to_dashboard');
      },

      setAddFilterController: function setAddFilterController(dropdownApi) {
        this.set('segmentsDropDownApi', dropdownApi);
      },

      onSelectReport: function onSelectReport(report) {
        this._createReportCustomRight(report);
      },

      onDeleteReport: function onDeleteReport(report) {
        var reportCustomRight = this.get('userReportsRights').filter(function (urr) {
          return urr.id === report.id;
        })[0];
        this.get('userReportsRights').removeObject(reportCustomRight);
        reportCustomRight.deleteRecord();
        reportCustomRight.save();
      },

      addReportHierarchy: function addReportHierarchy(value) {
        this.set('profile.reports_hierarchy', value);
      }
    }

  });
});