define('due-dashboard/helpers/compute-duration', ['exports', 'ember'], function (exports, _ember) {
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = _ember['default'].Helper.helper(function (duration) {
    if (!duration) return '-';

    var hours = Math.floor(duration / 60),
        minutes = Math.floor(duration % 60);

    return htmlSafe(hours + 'h&nbsp;' + minutes + 'min');
  });
});