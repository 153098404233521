define('due-dashboard/components/reports/array/-number-trend', ['exports', 'ember', 'due-dashboard/helpers/thousand-separator', 'due-dashboard/helpers/compute-duration'], function (exports, _ember, _dueDashboardHelpersThousandSeparator, _dueDashboardHelpersComputeDuration) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-number-trend'],

    kpiTypeRate: false,

    hideValue: computed('content', function () {
      var component = this.get('content.component');

      return component.config.trend && component.config.trend.hideValue;
    }),

    shareTranslation: computed('compValue.share', function () {
      var translation = this.get('i18n').t('components.reports.array.share').toString();
      return translation.replace("#total", this.get('compValue.share'));
    }),

    compValue: computed('value', function () {
      var _this = this;

      var value = this.get('value'),
          elem = this.get('elem'),
          content = this.get('content'),
          idx = content.component.config.index ? this.get('colIndex') - 1 : this.get('colIndex'),
          index = this.get('colIndex') - 1;

      var isComputable = content.component.properties[idx] && content.component.properties[idx].kpi && !content.component.properties[idx].kpi.includes('_time');

      if (value.number && typeof value.number === 'number' && value.total == null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.number);
      }

      if (value.total != null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.total);
      } else if (!isComputable) {
        value.number = value.total;
      }

      if (value.rate != null && value.total == null && value.number == null && isComputable) {
        value.number = _dueDashboardHelpersThousandSeparator['default'].compute(value.rate);
        value.rate = null;
      }

      if (elem && elem.format == 'duration') {
        value.number = _dueDashboardHelpersComputeDuration['default'].compute(value.number);
      }

      // Update to falsy condition ?
      if (value.number == 0) {
        value.number = content.component.properties[idx] && content.component.properties[idx].display_zero ? 0 : '-';
      } else if (value.number === undefined || value.number === null) {
        value.number = '-';
      }

      if (content.component.properties[idx] && content.component.properties[idx].attrs && !content.component.properties[idx].attrs.includes('trend')) {
        this.set('noTrend', true);
      }

      if (content.component.properties[index] && content.component.properties[index].hasOwnProperty('kpi') && content.component.properties[index].kpi.includes('rate')) {
        this.set('kpiTypeRate', true);
      }

      if (value.breakdown) {
        (function () {
          _this.set('contentConfig', content.component.properties[index].distribution);
          _this.set('title', content.component.config.translations[_this.get('currentLanguage')].headers[idx]);
          var filteredBrkdn = {};
          Object.keys(value.breakdown).forEach(function (text) {
            if (!text.includes('_')) {
              filteredBrkdn[text] = value.breakdown[text];
            }
          });

          value.distribution = filteredBrkdn;
        })();
      }

      return value;
    })
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-number-trend}} column with value and trend used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */