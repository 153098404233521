define('due-dashboard/routes/digest-export/new', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'due-dashboard/objects/reports/due-report-table-params', 'due-dashboard/objects/feedback-search/filter-set', 'due-dashboard/helpers/current-timezone'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _dueDashboardObjectsReportsDueReportTableParams, _dueDashboardObjectsFeedbackSearchFilterSet, _dueDashboardHelpersCurrentTimezone) {
  var Route = _ember['default'].Route;
  var service = _ember['default'].inject.service;
  exports['default'] = Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    queryParams: {
      query_id: {},
      start_date: {},
      end_date: {},
      api_key: {}
    },

    beforeModel: function beforeModel(transition) {
      var _super;

      return regeneratorRuntime.async(function beforeModel$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            _super = this._super.bind(this);
            context$1$0.next = 3;
            return regeneratorRuntime.awrap(this.get('session').authenticate('authenticator:access-token', { access_token: transition.queryParams.api_key }));

          case 3:
            context$1$0.next = 5;
            return regeneratorRuntime.awrap(this.get('currentAccount').loadCurrentUser());

          case 5:
            return context$1$0.abrupt('return', _super());

          case 6:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    model: function model(params) {
      var query, query_name, query_reports, query_report, report, report_name, report_elements, matching_element, table_params, global_filters, report_filters, merged, segments, auth_segments, default_segment;
      return regeneratorRuntime.async(function model$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return regeneratorRuntime.awrap(this.store.findRecord('exportable-report-query', params.query_id));

          case 2:
            query = context$1$0.sent;
            query_name = query.get('name');
            context$1$0.next = 6;
            return regeneratorRuntime.awrap(this.store.query('exportable-report-query-report', { filter: { exportable_report_query: params.query_id } }));

          case 6:
            query_reports = context$1$0.sent;
            query_report = query_reports.get('firstObject');
            context$1$0.next = 10;
            return regeneratorRuntime.awrap(this.store.findRecord('report', query_report.get('report').get('id')));

          case 10:
            report = context$1$0.sent;
            report_name = report.get('permalink');
            report_elements = report.get('elements');
            matching_element = _(report_elements).flatten().find(function (e) {
              return e.component.name === query_name;
            });
            table_params = _dueDashboardObjectsReportsDueReportTableParams['default'].create(matching_element);
            global_filters = this.get('_globalFilters.current');
            report_filters = _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWithLegacy(this.container, report.get('filters'));
            merged = global_filters.merge(report_filters);

            table_params.setFilters(merged.toDataQueryFormat());
            table_params.setDateRange(params.start_date, params.end_date);
            if (table_params.view_by) {
              segments = _.mapKeys(table_params.view_by.segments || table_params.segments, function (v, k) {
                return k.toLowerCase();
              }), auth_segments = this._selectAuthorized(segments), default_segment = table_params.default_view_by || _.keys(auth_segments)[0];

              table_params.setQuerySegments(auth_segments);
              if (default_segment) {
                table_params.object_id = auth_segments[default_segment];
              }
            }
            table_params.setTimezone(_dueDashboardHelpersCurrentTimezone['default'].compute());

            return context$1$0.abrupt('return', this.get('store').createRecord('segment-table-export-request', {
              content: JSON.stringify(table_params.toJson()),
              export_type: 'xlsx',
              content_type: 'full_hierarchy',
              file_file_name: report_name + '_' + query_name,
              send_email: false
            }).save());

          case 23:
          case 'end':
            return context$1$0.stop();
        }
      }, null, this);
    },

    redirect: function redirect(model) {
      this.replaceWith('digest-export.show', model);
    },

    // private:

    _globalFilters: service('feedback-search/global'),

    _selectAuthorized: function _selectAuthorized(segments) {
      if (this.get('currentAccount.content.profile') === 'custom') {
        var hierarchy = this.get('currentAccount.content.account_custom_profile_right.content.reports_hierarchy').map(function (h) {
          return h.toLowerCase();
        });

        return _.pick(segments, hierarchy);
      } else {
        return segments;
      }
    }
  });
});