define('due-dashboard/components/due-flexible-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({

    classNames: ['tag-badge', 'due-flexible-input'],
    tagName: 'li',
    type: 'text',
    attributeBindings: ['style'],

    style: computed('inputValue', function () {
      var styleProperties = '';
      if (this.get('inputValue').length > 0) {
        this.set('showClearFilter', true);
        styleProperties = 'width:' + (this.get('inputValue').length + 4) + 'ch;';
      } else {
        this.set('showClearFilter', false);
        styleProperties = 'width: 40px;';
      }
      this.set('styleProperties', htmlSafe(styleProperties));
      return htmlSafe(styleProperties);
    }),

    actions: {
      keyUpAction: function keyUpAction(term, trigger) {
        var keyUpAction = this.get('keyUpAction');
        if (keyUpAction) keyUpAction(term, trigger);
      },

      clearFilter: function clearFilter() {
        this.set('inputValue', '');
        var onClearFilter = this.get('onClearFilter');
        if (onClearFilter) onClearFilter();
      }
    }
  });
});