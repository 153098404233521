define('due-dashboard/components/graphs/due-pie', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var guidFor = _ember['default'].guidFor;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['due-pie'],

    placeholderPie: [{ total: 50, color: '#FAFAFA' }],

    didInsertElement: function didInsertElement() {
      this.set('uid', guidFor(this));
    },

    didRender: function didRender() {
      this._initPieChart(d3);
    },

    /**
     * Create pie chart using global d3
     * @param {Object} d3 global variable
     */
    _initPieChart: function _initPieChart(d3) {
      var data = this.get('placeholder') ? this.get('placeholderPie') : this.get('pie'),
          width = this.get('size') || 120,
          height = this.get('size') || 120,
          sizeOuterRadius = ({
        116: 58,
        120: 60
      })[width],
          sizeInnerRadius = ({
        116: 39,
        120: 40.5
      })[width];

      // svg creation
      var svg = d3.select('#pie-chart-svg-' + this.get('uid')).append("svg").attr("width", width).attr("height", height).append("g").attr("width", width).attr("height", height).attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // init d3 pie funciton
      var pie = d3.pie().sort(null).value(function (d) {
        return d.total;
      });

      // setting up raduises values
      var arc = d3.arc().innerRadius(sizeInnerRadius).outerRadius(sizeOuterRadius);

      // compute data values to d3 format
      var arcs = pie(data);

      // draw arcs
      svg.append("g").attr("width", width).attr("height", height).selectAll("path").data(arcs).enter().append("path").attr("fill", function (d) {
        return d.data.color;
      }).attr("d", arc);
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{due-pie-chart}} pie chart component
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2755%3A334944&t=vWBqgIrAQLBSPNiX-0
 */

/**
 * @module due-pie
 * @argument {Array} pie (Mandatory) List of chart values
 * @argument {Number} size (Optional) Pie chart size
 */